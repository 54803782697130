import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
  Select,
  z,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImgTextModule from '../components/elements/ImgTextModule';

import Stellenanzeige from '../components/stellenanzeige';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';
import AnimatedCountUpDecimal from '../components/UX/AnimatedCountUpDecimal';
import { AnimatePresence, motion } from 'framer-motion';
import KontaktFooter from '../components/KontaktFooter';

const Stellen = () => {
  const { setColorMode } = useColorMode();
  const [isToggledDVT, setToggleDVT] = useState(null);
  const updateStateModal = (toggleState, StellenID) => {
    setToggleDVT(toggleState);
    setModalIDstate(StellenID);
  };
  const [modalIDState, setModalIDstate] = useState(null);

  const [originalData, setOriginalData] = useState([]);
  const [availableJobs, setAvailableJobs] = useState([]);

  const [availableLocations, setAvailableLocations] = useState(['']);

  const [selectedLocation, setSelectedLocation] = useState(['']);
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const [totalJobs, setTotalJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const stellenPerPage = 16;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastStelle = currentPage * stellenPerPage;
  const indexOfFirstStelle = indexOfLastStelle - stellenPerPage;
  const currentJobs = availableJobs.slice(
    indexOfFirstStelle,
    indexOfLastStelle
  );

  function Pagination({
    stellenPerPage,
    availableJobs,
    paginate,
    currentPage,
  }) {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(availableJobs / stellenPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <Flex className='pagination ' justifyContent='center'>
          {pageNumbers.map((number) => (
            <Button
              onClick={() => paginate(number)}
              key={number}
              href='#'
              className={`page-item ${currentPage === number ? 'active' : ''}`}
              flex={1}
              textAlign={'center'}
              bg={`${currentPage === number ? 'brand.yellow' : 'brand.violet'}`}
              px='0rem'
              mr='0.5rem'
              minW='1rem'
              maxW='2.5rem'
              fontSize={'xs'}
              rounded={'full'}
              color={'white'}
              _hover={{
                bg: 'brand.yellow',
              }}
              _focus={{
                bg: 'blue.500',
              }}
            >
              {number}
            </Button>
          ))}
        </Flex>
      </nav>
    );
  }
  const fetchData = async () => {
    axios
      .get('https://api.kdw-hr.de/cache/stelle_data_StelleUrl.json')
      .then((response) => {
        setOriginalData(response.data.Items);
        setAvailableJobs(response.data.Items);
        setTotalJobs(response.data.TotalItems);
      });
  };
  const fetchLocations = async () => {
    axios
      .get('https://api.kdw-hr.de/cache/stelle_data_StelleUrl.json')
      .then((response) => {
        let locations = response.data.Items.map((item) => item.EinsatzortOrt);
        let uniqueLocation = Array.from(new Set(locations)).sort();
        setAvailableLocations(uniqueLocation);
      });
  };

  useEffect(() => {
    fetchData();
    fetchLocations();
    setSelectedDepartment('');
    setSelectedLocation('');
  }, []);

  useEffect(() => {
    setCurrentPage(1);

    setAvailableJobs(
      originalData.filter((item) => {
        if (selectedDepartment !== '') {
          return (
            item.Abteilung !== '' &&
            item.Abteilung === selectedDepartment &&
            (selectedLocation === '' || item.EinsatzortOrt === selectedLocation)
          );
        } else if (selectedLocation !== '') {
          return (
            item.EinsatzortOrt !== '' && item.EinsatzortOrt === selectedLocation
          );
        } else {
          return true;
        }
      })
    );
    setTotalJobs(availableJobs.length);
  }, [selectedLocation, selectedDepartment]);

  /*   useEffect(() => {
    setCurrentPage(1);
    let filteredData = originalData;

    if (selectedLocation !== '') {
      filteredData = filteredData.filter(
        (item) => item.EinsatzortOrt === selectedLocation
      );
    }

    if (selectedDepartment !== '') {
      filteredData = filteredData.filter(
        (item) => item.Abteilung === selectedDepartment
      );
    }

    setAvailableJobs(filteredData);
    setTotalJobs(filteredData.length);
  }, [selectedLocation, selectedDepartment]);
 */
  return (
    <>
      <Helmet>
        <title>KDW | Stellenanzeigen</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>
      <ImgTextModule
        src={
          '439342_Chess_Chess_Pieces_Game_King_By_Storyfootage_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='75vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        {/*  <Box
          zIndex={99999}
          position='fixed'
          bg='white'
          overflow='scroll'
          maxH='98vh'
          p={8}
          size='xs'
          fontSize='xs'
        >
          <Text>DEBUG</Text>
          <br />
          Seite:{currentPage}
          <br /> Jobanzahl: {availableJobs.length}
          <br /> abteilung: {selectedDepartment}
          <br /> ort: {selectedLocation}
        </Box> */}
        <Container alignSelf={'end'} variant='layoutContainer' mt={18}>
          <Box p={{ base: 0, lg: '8' }}>
            <Flex flexDirection={'column'}>
              <Box>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.7}
                  initialX={0}
                  initialY={-10}
                ></FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.4}
                  initialX={0}
                  initialY={-10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    mx={'auto'}
                    textAlign={'center'}
                    fontSize={{ base: '2xl', lg: '3xl' }}
                  >
                    Jobs
                  </Text>
                  <Text
                    color='brand.green'
                    fontWeight='normal'
                    mx={'auto'}
                    textAlign={'center'}
                    fontSize={{ base: '3xl', lg: '6xl' }}
                  >
                    Wir sind das perfekte Match
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={1}
                  duration={0.7}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='brand.yellow'
                    mb={2}
                    mx={'auto'}
                    textAlign={'center'}
                    maxW={{ base: '100%', lg: '80%' }}
                    fontSize={{ base: '2xl', lg: '4xl' }}
                    lineHeight={'short'}
                  >
                    für Ihren nächsten Schritt.
                  </Text>
                </FadeInAnimation>
              </Box>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {(inView) => (
                  <Flex
                    mx={'auto'}
                    w={'30vw'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    mt={12}
                    justifyContent={{ base: 'center', lg: 'center' }}
                  >
                    <AnimatedCountUpDecimal
                      inView={inView}
                      unit=' '
                      fontSizeUnit='lg'
                      count={availableJobs.length}
                      fontSize={'6rem'}
                      duration={1}
                      decimal={false}
                      decimalDuration={2}
                      decimalCount={0}
                      unitSpacing={3}
                      numberColor={'white'}
                      textColor={'white'}
                      cellHeight={'6rem'}
                      letterSpacing={'-0.2rem'}
                    ></AnimatedCountUpDecimal>
                    <Text
                      color='brand.yellow'
                      mb={2}
                      mx={'auto'}
                      textAlign={'center'}
                      maxW={{ base: '100%', lg: '80%' }}
                      fontSize={{ base: 'md', lg: 'md' }}
                      lineHeight={'short'}
                    >
                      freie Stellen bundesweit.
                    </Text>
                  </Flex>
                )}
              </InViewTrigger>
            </Flex>
          </Box>
        </Container>
      </ImgTextModule>
      <Container variant='layoutContainer' py={24}>
        <FadeInAnimation
          threshold={0.4}
          delay={1}
          duration={0.9}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.yellow'
            textAlign={'center'}
            mb={2}
            s
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '2xl', lg: '3xl' }}
            lineHeight={'short'}
          >
            Unsere freien Stellen im kaufmännischen und technischen Bereich.
          </Heading>
        </FadeInAnimation>
        <Flex
          maxW={{ base: '100%', lg: '70%' }}
          w={{ base: '100%', lg: '70%' }}
          mx='auto'
          mt={12}
          wrap='nowrap'
          justify='center'
        >
          {!selectedDepartment ? (
            <Button
              mb={4}
              bgColor='brand.yellow'
              px={8}
              mr={4}
              onClick={() => {
                setSelectedDepartment('');
              }}
            >
              Alle
            </Button>
          ) : (
            <Button
              mb={4}
              px={8}
              mr={4}
              onClick={() => {
                setSelectedDepartment('');
              }}
            >
              Alle
            </Button>
          )}
          {selectedDepartment === 'Office' ? (
            <Button
              mb={4}
              px={8}
              bgColor='brand.yellow'
              mr={4}
              onClick={() => {
                setSelectedDepartment('Office');
              }}
            >
              Office
            </Button>
          ) : (
            <Button
              mb={4}
              px={8}
              mr={4}
              onClick={() => {
                setSelectedDepartment('Office');
              }}
            >
              Office
            </Button>
          )}
          {selectedDepartment === 'Industrie' ? (
            <Button
              mb={4}
              bgColor='brand.yellow'
              px={8}
              mr={4}
              onClick={() => {
                setSelectedDepartment('Industrie');
              }}
            >
              Industrie
            </Button>
          ) : (
            <Button
              mb={4}
              px={8}
              mr={4}
              onClick={() => {
                setSelectedDepartment('Industrie');
              }}
            >
              Industrie
            </Button>
          )}
          {availableLocations && (
            <Select
              maxW={{ base: '100%', lg: '30%' }}
              w={{ base: '100%', lg: '30%' }}
              placeholder='Alle Orte'
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              {availableLocations.map((filtersEinsatzort) => (
                <option value={filtersEinsatzort}>{filtersEinsatzort}</option>
              ))}
            </Select>
          )}
        </Flex>

        <InViewTrigger threshold={0.1} triggerOnce={false}>
          {(inView) => (
            <Flex
              mx={'auto'}
              w={'30vw'}
              flexDirection={'column'}
              alignItems={'center'}
              mt={4}
              justifyContent={{ base: 'center', lg: 'center' }}
            >
              <AnimatedCountUpDecimal
                inView={inView}
                unit=' '
                fontSizeUnit='lg'
                count={availableJobs.length}
                fontSize={'2rem'}
                duration={1}
                decimal={false}
                decimalDuration={2}
                decimalCount={0}
                unitSpacing={3}
                numberColor={'brand.violet'}
                textColor={'white'}
                cellHeight={'2rem'}
                letterSpacing={'-0.1rem'}
              ></AnimatedCountUpDecimal>
              <Text
                color='brand.yellow'
                mb={2}
                mx={'auto'}
                textAlign={'center'}
                maxW={{ base: '100%', lg: '80%' }}
                fontSize={{ base: 'md', lg: 'md' }}
                lineHeight={'short'}
              >
                Ergebnisse
              </Text>
            </Flex>
          )}
        </InViewTrigger>
        <Pagination
          stellenPerPage={stellenPerPage}
          availableJobs={availableJobs.length}
          paginate={paginate}
          currentPage={currentPage}
        />
        <Flex wrap='wrap' justify='center' mx='auto'>
          {currentJobs.map((currentStellen) => (
            <AnimatePresence>
              <motion.div
                layout
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -10 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1,
                  delay: 0,
                }}
              >
                <Stellenanzeige
                  mx={4}
                  sx={{ border: '1px' }}
                  updateStateModal={updateStateModal}
                  CultureName={currentStellen.CultureName}
                  key={currentStellen.StellenID}
                  StellenID={currentStellen.StellenID}
                  Abteilung={currentStellen.Abteilung}
                  Bezeichnung={currentStellen.Bezeichnung}
                  Beginn={currentStellen.Beginn}
                  EinsatzPlz={currentStellen.EinsatzPlz}
                  EinsatzOrt={currentStellen.EinsatzortOrt}
                  Vertragsarten={currentStellen.Vertragsarten}
                  DatumAb={currentStellen.DatumAb}
                  StellenUUID={currentStellen.StelleUuid}
                  LinkUrl={currentStellen.ZusaetzlicheEigenschaften.StelleUrl}
                  VertragsartenString={currentStellen.VertragsartenString}
                />
              </motion.div>
            </AnimatePresence>
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default Stellen;
